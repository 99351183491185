exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_menu__1jEhZ .ant-menu-item-divider {\n  height: 36px !important;\n  margin-top: 6px !important;\n  width: 1px !important;\n  float: left; }\n\n.style_menu__1jEhZ .ant-menu-dark .ant-menu-item-divider {\n  background: #514d6a !important; }\n\n.style_menu__1jEhZ .ant-menu-dark .style_icon__2uMT8 {\n  color: #b8beca; }\n\n.style_menu__1jEhZ .ant-menu-dark .style_title__2Fv7E {\n  color: #b8beca; }\n\n.style_menu__1jEhZ .ant-menu-light .style_icon__2uMT8 {\n  color: #74708d; }\n\n.style_menu__1jEhZ .ant-menu-light .style_title__2Fv7E {\n  color: #74708d; }\n\n.style_menuTop__3gjrH {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  align-items: center;\n  background: #fff;\n  box-shadow: inset 0 -1px 0 #e8e8e8;\n  position: sticky;\n  top: 0;\n  z-index: 999; }\n  .style_menuTop__3gjrH ul[role='menu'] {\n    flex-basis: 100%; }\n\n.style_logo__2h3ZA {\n  height: 48px; }\n\n.style_logoContainer__qwXh6 {\n  height: 48px;\n  padding: 9px 20px 0 17px; }\n  .style_logoContainer__qwXh6 img {\n    height: 28px; }\n\n.style_icon__2uMT8 {\n  margin-top: 1.23rem;\n  margin-right: 0.53rem; }\n  @media (max-width: 991px) {\n    .style_icon__2uMT8 {\n      display: none !important; } }\n\n.settings__menuTop .style_menu__1jEhZ {\n  display: inline-flex;\n  flex-direction: row; }\n  .settings__menuTop .style_menu__1jEhZ .style_icon__2uMT8 {\n    order: -1; }\n\n.settings__menuTop .ant-menu-item {\n  display: inline-flex;\n  flex-direction: row; }\n  .settings__menuTop .ant-menu-item .style_icon__2uMT8 {\n    order: -1; }\n\n.settings__menuTop .ant-menu-light {\n  border-bottom: none;\n  box-shadow: inset 0 -1px 0 #e8e8e8; }\n", ""]);

// exports
exports.locals = {
	"menu": "style_menu__1jEhZ",
	"icon": "style_icon__2uMT8",
	"title": "style_title__2Fv7E",
	"menuTop": "style_menuTop__3gjrH",
	"logo": "style_logo__2h3ZA",
	"logoContainer": "style_logoContainer__qwXh6"
};