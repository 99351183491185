exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_dropdown__6VYUL {\n  padding-right: 15px;\n  position: relative;\n  cursor: pointer; }\n  .style_dropdown__6VYUL::after {\n    color: #d2d9e5;\n    position: absolute;\n    top: 50%;\n    right: 0;\n    margin-top: -2px;\n    display: inline-block;\n    width: 0;\n    height: 0;\n    margin-left: 0.255em;\n    vertical-align: 0.255em;\n    content: '';\n    border-top: 0.3em solid;\n    border-right: 0.3em solid transparent;\n    border-bottom: 0;\n    border-left: 0.3em solid transparent;\n    transition: all 0.2s ease-in-out; }\n  .style_dropdown__6VYUL:hover {\n    color: #08f; }\n    .style_dropdown__6VYUL:hover::after {\n      color: #b8beca; }\n\n.style_avatar__2YCC4 {\n  background-color: #e4e9f0; }\n\n.style_menuIcon__2PpXM {\n  margin-right: 0.38rem; }\n", ""]);

// exports
exports.locals = {
	"dropdown": "style_dropdown__6VYUL",
	"avatar": "style_avatar__2YCC4",
	"menuIcon": "style_menuIcon__2PpXM"
};