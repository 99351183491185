import React from 'react'
import PropTypes from 'prop-types'

import './style.scss'
import InfoButtonTooltip from '../InfoButtonTooltip'

const propTypes = {
  /** Label of the field */
  name: PropTypes.node,
  /** Value of the field */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hidden: PropTypes.bool,
}
const defaultProps = {
  name: '',
  value: '',
  hidden: false,
}
const StaticFieldView = ({ name, value, style, hidden, tooltip, inputLike }) => {
  return (
    <div
      className={`static-item static-item--${inputLike && 'input-like'}`}
      style={style}
      hidden={hidden}
    >
      <small className="static-item__label">
        {name}
        {tooltip && (
          <>
            &nbsp;<InfoButtonTooltip>{tooltip}</InfoButtonTooltip>
          </>
        )}
      </small>
      <span className="static-item__input">{value}</span>
    </div>
  )
}

StaticFieldView.propTypes = propTypes
StaticFieldView.defaultProps = defaultProps

export default StaticFieldView
