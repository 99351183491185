import { ROLES } from './roles'

export const PERMISSIONS = {
  /**
   * ? HELPERS
   */
  everyone: [
    ROLES.superAdmin,
    ROLES.fullAdmin,
    ROLES.restrictedAdmin,
    ROLES.viewOnly,
    ROLES.restrictedViewOnly,
  ],
  nobody: [],

  /**
   * * LOAN
   */
  // Can change status of the Loan
  changeLoanStatus: [ROLES.superAdmin],
  // Can see list of Loans (Pipeline & Live)
  seeLoanLists: [ROLES.superAdmin, ROLES.fullAdmin, ROLES.restrictedAdmin, ROLES.viewOnly],
  // Can see details of the Loans
  seeLoanDetails: [ROLES.superAdmin, ROLES.fullAdmin, ROLES.restrictedAdmin, ROLES.viewOnly],
  // Can add cashflow event for live loan
  canModifyCashEvent: [ROLES.superAdmin, ROLES.fullAdmin],
  // Can save pipeline loan
  savePipelineLoan: [ROLES.superAdmin, ROLES.fullAdmin, ROLES.restrictedAdmin],

  /**
   * * DASHBOARD
   */
  // Can see dashboards
  seeDashboards: [
    ROLES.superAdmin,
    ROLES.fullAdmin,
    ROLES.restrictedAdmin,
    ROLES.viewOnly,
    ROLES.restrictedViewOnly,
  ],
  // Can see dashboards filters
  seeDashboardFilters: [ROLES.superAdmin, ROLES.fullAdmin, ROLES.restrictedAdmin, ROLES.viewOnly],
  // Can see LW Fee dashboard
  seeLwFeeDashboard: [ROLES.superAdmin, ROLES.fullAdmin, ROLES.viewOnly],

  /**
   * * OTHER
   */
  // Can modify tags and relations in the system
  modifyTagsAndRelations: [ROLES.superAdmin, ROLES.fullAdmin, ROLES.restrictedAdmin],
  // Can confirm KYC
  canConfirmKyc: [ROLES.superAdmin, ROLES.fullAdmin],

  /**
   * * CMS
   */
  // Can see Borrower CMS
  seeBorrowerCms: [
    ROLES.superAdmin,
    ROLES.fullAdmin,
    ROLES.restrictedAdmin,
    ROLES.viewOnly,
    ROLES.restrictedViewOnly,
  ],
  // Can see CMS sections
  seeCms: [ROLES.superAdmin, ROLES.fullAdmin, ROLES.restrictedAdmin, ROLES.viewOnly],
  // Can modify entities in CMS sections
  modifyCms: [ROLES.superAdmin, ROLES.fullAdmin, ROLES.restrictedAdmin],
  // Can see DMS
  seeDms: [ROLES.superAdmin, ROLES.fullAdmin, ROLES.restrictedAdmin, ROLES.viewOnly],
  // Can modify files in DMS sections
  modifyDms: [ROLES.superAdmin, ROLES.fullAdmin],
  // Can see Users CMS section
  seeUsers: [ROLES.superAdmin, ROLES.fullAdmin],
  // Can modify Users
  modifyUsers: [ROLES.superAdmin],
  // Can see calendar
  seeCalendar: [ROLES.superAdmin, ROLES.fullAdmin, ROLES.restrictedAdmin, ROLES.viewOnly],
  // Can modify calendar tasks
  modifyCalendarTasks: [ROLES.superAdmin, ROLES.fullAdmin],
  // Can see email
  seeEmails: [ROLES.superAdmin, ROLES.fullAdmin, ROLES.restrictedAdmin, ROLES.viewOnly],
}

export default {
  PERMISSIONS,
}
