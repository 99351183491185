import { generate } from '../generator'
import { generateGroup } from '../groupGenerator'
import * as revertToPipeline from './revertToPipeline'

const group = generateGroup('LOAN', 'loans', {
  paginated: true,
})

const all = generate('LOAN', 'loans', {
  reducerActions: { ...group.reducerActions, ...revertToPipeline.reducerActions },
  creators: { ...group.restCreators, ...revertToPipeline.creators },
  sagas: [...group.sagas, ...revertToPipeline.sagas],
})

const { saga, reducer, actions, creators } = all

export { saga, reducer, actions, creators }

export default {
  saga: all.saga,
  reducer: all.reducer,
  actions: all.actionNames,
  creators: all.action,
}
