import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ROLES } from '../config/roles'

export default function useRole() {
  const user = useSelector((state) => state.user)
  const userRole = useMemo(() => user?.roles?.[0]?.id || ROLES.noAccess, [user])

  return userRole
}
