import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

import './style.scss'

const InfoButtonTooltip = ({ children }) => {
  return (
    <Tooltip title={<div className="tooltip-content">{children}</div>}>
      <InfoCircleOutlined style={{ color: '#1890FF' }} />
    </Tooltip>
  )
}

export default InfoButtonTooltip
