import API from './api'

/**
 * Clones copy with given ID, with all its dependencies
 * @param {number} loanId
 * @param {boolean} [isToPipeline=false] - if true, cloned loan will be in pipeline, else it will be cloned as it is
 * @returns {boolean}
 */
export const cloneLoan = async (loanId, isToPipeline = false) => {
  const path = isToPipeline ? 'copy_to_pipeline' : 'copy'

  try {
    const response = await API.call(`loans/${path}/${loanId}`, 'GET')
    const data = await response.json()
    const newLoanId = data.data.id
    console.log(data.message, `[ID: ${newLoanId}]`)
    return newLoanId
  } catch (err) {
    console.error(err)
    return false
  }
}

// TODO: use in some component
window.cloneLoan = cloneLoan

export default {
  cloneLoan,
}
