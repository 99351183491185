import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Select } from 'antd'

import './autocomplete.scss'
import { CloseOutlined } from '@ant-design/icons'
import { MODELS } from '../../config'
import BondSelect from '../BondSelect'

const { Option } = Select

const classes = [...MODELS]

class AutocompleteBond extends Component {
  static propTypes = {
    /** array of default tags for autocomplete */
    onSelect: PropTypes.func,
    classType: PropTypes.string,
    isBlock: PropTypes.bool,
    /** values passed in this array will be removed from list */
    exclude: PropTypes.array,
    /** this type will not be visible on choose type select */
    except: PropTypes.string,
    /** used to set value of BondSelect from outside (cannot be simply `value` becouse of AntDesign errors) */
    forcedValue: PropTypes.string,
    /** Custom parameters added to request e.g. `{foo: 'bar'}` will add at the end of the request query `?foo=bar` */
    customFilter: PropTypes.object,
    required: PropTypes.string,
    isStatic: PropTypes.bool,
  }

  static defaultProps = {
    onSelect: () => {},
    classType: null,
    isBlock: false,
    exclude: [],
    except: '',
    forcedValue: '',
    customFilter: {},
    isStatic: false,
    required: '',
  }

  constructor(props) {
    super(props)

    const classList = classes.filter((c) => c.key !== props.except)

    this.state = {
      classes: classList,
      model: props.classType || classList[0].key,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.classType !== this.props.classType) {
      this.setState((prevState) => ({
        model: this.props.classType || prevState.classes[0].key,
      }))
    }

    if (prevProps.except !== this.props.except) {
      this.setState((prevState) => {
        const returnObj = {
          classes: classes.filter((c) => c.key !== this.props.except),
        }

        if (this.props.classType === this.props.except) {
          returnObj.model = prevState.classes[0].key
        }

        return returnObj
      })
    }
  }

  onChangeModel = (v) => {
    this.setState({ model: v })
  }

  render() {
    const { className, classType, isBlock, exclude, customFilter } = this.props
    const { model } = this.state
    const modelLayout = classType || isBlock ? 'col-12' : 'col-5'
    const findLayout = classType || isBlock ? 'col-12' : 'col-7'

    const modelName = classes.find((c) => c.key === this.state.model).name

    return (
      <div className={`autocomplete-bond container ${className || ''}`}>
        <div className="row no-gutters">
          <div
            className={`autocomplete-bond__field-wrapper autocomplete-bond__field-wrapper--model ${modelLayout}`}
            style={classType ? { display: 'none' } : {}}
          >
            <small className="autocomplete-bond__field-title">Relation type:</small>
            <Select
              className="autocomplete-bond__field autocomplete-bond__field--select"
              value={model}
              onChange={this.onChangeModel}
              placeholder={this.props.placeholder}
              disabled={this.props.isStatic}
            >
              {this.state.classes.map((c) => (
                <Option key={c.key} value={c.key}>
                  {c.name.replace(/([a-z](?=[A-Z]))/g, '$1 ')}
                </Option>
              ))}
            </Select>
          </div>

          <div
            className={`autocomplete-bond__field-wrapper autocomplete-bond__field-wrapper--find ${findLayout}`}
          >
            {classType ? (
              ''
            ) : (
              <small className="autocomplete-bond__field-title">Find {modelName}:</small>
            )}
            <BondSelect
              placeholder={`Start typing to find ${modelName}`}
              className="autocomplete-bond__field autocomplete-bond__field--select"
              bondKey={model}
              forcedValue={this.props.value || this.props.forcedValue}
              onChange={this.props.onSelect}
              exclude={exclude}
              customFilter={customFilter}
              isStatic={this.props.isStatic}
              required={this.props.required}
            />
            {this.props.value && (
              <Button
                shape="circle"
                size="small"
                className="autocomplete-bond__reset-btn"
                type="dashed"
                onClick={() => this.onChangeModel(null)}
                icon={<CloseOutlined />}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default AutocompleteBond
