import { createSagaAPICall } from '../generator'

const PREFIX = 'LOAN_REVERT_TO_PIPELINE'

export const ACTIONS = {
  REVERT_TO_PIPELINE_REQUEST: `${PREFIX}_REQUEST`,
  REVERT_TO_PIPELINE_SUCCESS: `${PREFIX}_SUCCESS`,
  REVERT_TO_PIPELINE_FAILURE: `${PREFIX}_FAILURE`,
}

export const reducerActions = {
  [ACTIONS.REVERT_TO_PIPELINE_REQUEST]: (state) => state,
  [ACTIONS.REVERT_TO_PIPELINE_SUCCESS]: (state, action) => ({
    ...state,
    error: false,
    loading: false,
    byId: {
      ...state.byId,
      [action.payload.data.id]: {
        ...state.byId[action.payload.data.id],
        ...action.payload.data,
        loading: false,
        error: false,
      },
    },
  }),
  [ACTIONS.REVERT_TO_PIPELINE_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    byId: {
      ...state.byId,
      [action.data.id]: {
        ...state.byId[action.data.id],
        loading: false,
        error: action.payload?.data || action.payload?.message || true,
        code: action.statusCode || 404,
      },
    },
  }),
}

export const creators = {
  revertToPipeline: (id) => {
    return {
      type: ACTIONS.REVERT_TO_PIPELINE_REQUEST,
      id,
    }
  },
}

export const sagas = [createSagaAPICall(PREFIX, 'loans/live_to_pipeline')]

export default {
  reducerActions,
  creators,
  sagas,
}
