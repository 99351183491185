import { logout, refreshAccessToken } from '../redux/user/actions'
import { store } from '../redux/store'

// ? How many miliseconds before token expity should refresh token request be sent
const REFRESH_BEFORE_EXPIRY_TIME = 50 * 1000

const dispatchRefreshToken = () => store.dispatch(refreshAccessToken())
const dispatchLogout = () => store.dispatch(logout())

const calcTimeToExpire = (expiryDate) => {
  const now = new Date().getTime()
  return expiryDate - now
}

export const watchToTokenRefresh = () => {
  const { user } = store.getState()

  const timeToExpire = calcTimeToExpire(user.expiry_date)
  const timeToRefresh = timeToExpire - REFRESH_BEFORE_EXPIRY_TIME

  if (timeToExpire < 0) return

  // TODO: remove console.log
  console.log('Refresh AccessToken in:', `${timeToRefresh / 1000}s`)
  // window.refreshTokenNow = dispatchRefreshToken

  setTimeout(() => {
    const {
      user: { token },
    } = store.getState()

    if (token) dispatchRefreshToken()
  }, Math.max(timeToRefresh, 0))
}

export const watchForTokenExpires = () => {
  return setInterval(() => {
    const { user } = store.getState()
    if (!user.token) return

    const timeToExpire = calcTimeToExpire(user.expiry_date)
    // console.log('Token expiry check')

    if (timeToExpire <= 0) {
      dispatchLogout()
    }
  }, 2000)
}

export default {
  watchToTokenRefresh,
}
