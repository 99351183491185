/**
 * Checks if given role has permission to perform action
 * @param {number[]} actionPermisions
 * @returns {function(userRole: number) => boolean}
 */
export const checkPermissions = (actionPermisions) => (userRole) =>
  actionPermisions.some((requiredRole) => requiredRole === userRole)

export default {
  checkPermissions,
}
