import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Checkbox, DatePicker, Input, InputNumber, Select, Slider } from 'antd'
import _ from 'lodash'
import moment from 'moment'

import useFormContext from '../../context/form/hook'
import HasMany from '../HasMany'
import BondSelect from '../BondSelect'
import AutocompleteBond from '../AutocompleteBond'
import StaticFieldView from '../StaticFieldView'
import { formatStaticValue } from '../../utils/utils'
import useDisabledFields from '../../context/disabledFields/hook'
import { CALC_DATE_FORMAT, VISIBLE_DATE_FORMAT } from '../../config/dates'
import {
  convertDecimalToCurrencyFormat,
  removeAnyWhitespace,
  convertToNumericString,
  trimRedundantChar,
} from '../../utils/stringUtils'
import usePermissions from '../../hooks/usePermissions'
import { PERMISSIONS } from '../../config/permissions'

const { TextArea } = Input

const getField = (item, form, data, disabled) => {
  switch (item.type) {
    case 'select':
      return (
        <Select
          disabled={item.disabled || disabled}
          mode={item.mode || 'default'}
          onChange={item.onChange}
          placeholder={item.placeholder}
          {...(item.inputProps || {})}
        >
          {item.options.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      )
    case 'checkbox':
      return (
        <Checkbox
          disabled={item.disabled || disabled}
          onChange={(e) => item.onChange?.(e, form)}
          {...(item.inputProps || {})}
        >
          {item.name}
        </Checkbox>
      )
    case 'slider':
      return (
        <Slider
          range={item.range}
          min={item.min}
          max={item.max}
          disabled={item.disabled || disabled}
          onChange={(e) => item.onChange?.(e, form)}
          {...(item.inputProps || {})}
        />
      )
    case 'number':
      return (
        <InputNumber
          disabled={item.disabled || disabled}
          placeholder={`${item.name}`}
          onChange={item.onChange}
          {...(item.inputProps || {})}
        />
      )
    case 'amount':
      return (
        <InputNumber
          disabled={item.disabled || disabled}
          precision={2}
          min={0}
          formatter={(value) => value |> convertDecimalToCurrencyFormat}
          parser={(value) =>
            value |> removeAnyWhitespace |> trimRedundantChar |> convertToNumericString
          }
          onChange={(value) => item.onChange?.(value, form)}
          {...(item.inputProps || {})}
        />
      )
    case 'percent':
      return (
        <InputNumber
          disabled={item.disabled || disabled}
          min={0}
          max={100}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace('%', '')}
          onChange={(value) => item.onChange?.(value, form)}
          {...(item.inputProps || {})}
        />
      )
    case 'textarea':
      return (
        <TextArea
          disabled={item.disabled || disabled}
          placeholder={`${item.name}`}
          autoSize={{ minRows: 6 }}
          {...(item.inputProps || {})}
        />
      )
    case 'hasmany':
      return (
        <HasMany
          disabled={item.disabled || disabled}
          classType={item.classType}
          initialValues={_.get(data, item.key)}
          {...(item.inputProps || {})}
        />
      )
    case 'bondselect':
      return (
        <BondSelect
          disabled={item.disabled || disabled}
          bondKey={item.classType}
          forcedValue={_.get(data, item.key) || null}
          whitelist={item.whitelist || []}
          options={item.options}
          {...(item.inputProps || {})}
        />
      )
    case 'autobond':
      return (
        <AutocompleteBond
          disabled={item.disabled || disabled}
          className={item.className}
          classType={item.classType}
          isBlock={item.isBlock}
          onSelect={item.onSelect || (({ id }) => form.setFieldsValue({ [item.key]: id }))}
          forcedValue={_.get(data, item.key) || null}
          {...(item.inputProps || {})}
        />
      )
    case 'date':
      return (
        <DatePicker
          disabled={item.disabled || disabled}
          format={VISIBLE_DATE_FORMAT}
          showTime={false}
          onChange={(value) => item.onChange?.(value, form)}
          {...(item.inputProps || {})}
        />
      )
    case 'daterange':
      return (
        <DatePicker.RangePicker
          disabled={item.disabled || disabled}
          format={VISIBLE_DATE_FORMAT}
          showTime={false}
          onChange={(value) => item.onChange?.(value, form)}
          {...(item.inputProps || {})}
        />
      )
    case 'password':
      return <Input.Password placeholder={`${item.name}`} {...(item.inputProps || {})} />
    case 'input':
    default:
      return (
        <Input
          disabled={item.disabled || disabled}
          placeholder={`${item.name}`}
          {...(item.inputProps || {})}
        />
      )
  }
}

const getDefaultValue = (item, data, form) => {
  const defaultValue = item.value ? item.value(data, form) : _.get(data, item.key)

  if (defaultValue === undefined && item.initialValue !== undefined) {
    return item.initialValue
  }

  return defaultValue
}

const getDecoratorConfig = (item, data, isNew, form) => {
  const itemRules = item.getRules ? item.getRules(form, data) : item.rules

  if (isNew) {
    return {
      initialValue: item.initialValue,
      rules: itemRules,
    }
  }

  const decorator = {
    initialValue: getDefaultValue(item, data, form),
    rules: itemRules,
  }

  switch (item.type) {
    case 'select':
      decorator.valuePropName = 'value'
      break
    case 'checkbox':
      decorator.valuePropName = 'checked'
      break
    case 'bond':
      decorator['option.initialValue'] = _.get(data, item.key)
      break
    case 'date':
      decorator.initialValue = moment(_.get(data, item.key), CALC_DATE_FORMAT)
      if (!decorator.initialValue.isValid()) decorator.initialValue = null
      break
    default:
  }
  return decorator
}

const getError = (errors, key) => {
  if (errors && errors[key]) {
    return 'error'
  }
  return undefined
}

const getHelp = (errors, key) => {
  if (errors && errors[key]) {
    return errors[key].join(' ')
  }
  return undefined
}

const propTypes = {
  groupKey: PropTypes.string,
  item: PropTypes.object.isRequired,
  label: PropTypes.string,
  errors: PropTypes.object,
  data: PropTypes.object.isRequired,
  isNew: PropTypes.bool,
  form: PropTypes.object,
  /** Disables running onChange function when component inits */
  disableOnMount: PropTypes.bool,
  hidden: PropTypes.bool,
  isStatic: PropTypes.bool,
  /** Forces FormItem to be enabled even if context says that it should be disabled */
  forceEnabled: PropTypes.bool,
  colon: PropTypes.bool,
}

const defaultProps = {
  groupKey: null,
  isNew: false,
  label: null,
  form: null,
  errors: null,
  disableOnMount: false,
  hidden: false,
  isStatic: false,
  forceEnabled: false,
  colon: true,
}

const CmsFormItem = ({
  groupKey,
  item,
  label,
  data,
  isNew,
  form,
  errors,
  disableOnMount,
  hidden,
  style,
  className,
  isStatic,
  forceEnabled,
  colon,
  capitalizeStatic = true,
}) => {
  const moddedItem = useMemo(
    () => ({
      ...item,
      key: groupKey ? `${groupKey}.${item.key}` : item.key,
    }),
    [item, groupKey],
  )
  const disabled = forceEnabled ? false : useDisabledFields()

  useEffect(() => {
    // Run input onChanges on init
    if (!disableOnMount && form && moddedItem.onChange) {
      moddedItem.onChange(null, form, { init: true })
    }
  }, [])

  if (!label && label !== '') {
    label = moddedItem.type !== 'checkbox' ? moddedItem.name : null
  }

  if (!form) {
    form = useFormContext()
  }

  const { getFieldDecorator } = form
  const hasEditPermissions = usePermissions(item.editPermissions || PERMISSIONS.everyone)
  const field = getField(moddedItem, form, data, disabled || !hasEditPermissions)

  if (moddedItem.type === 'hidden') {
    getFieldDecorator(moddedItem.key, getDecoratorConfig(moddedItem, data, isNew, form))
    return null
  }

  const staticField = useMemo(() => {
    let value = form.getFieldValue(moddedItem.key) ?? _.get(data, moddedItem.key)

    if (moddedItem.type === 'select') {
      value = moddedItem.options.find((opt) => opt.value === value)?.label || value
    }

    if (moddedItem.type === 'checkbox') {
      return (
        <div className="ant-legacy-form-item-control ant-row ant-legacy-form-item">
          <Checkbox checked={value} disabled>
            {moddedItem.name}
          </Checkbox>
        </div>
      )
    }

    return (
      <StaticFieldView
        name={moddedItem.name}
        value={formatStaticValue(value, moddedItem.type, capitalizeStatic)}
        hidden={hidden}
        style={style}
        className={className}
        inputLike
      />
    )
  }, [moddedItem, data, isNew, form, className, style, hidden])

  return (
    <>
      {isStatic && staticField}
      <div style={{ display: isStatic ? 'none' : 'block' }} className={className}>
        <Form.Item
          validateStatus={getError(errors, moddedItem.key)}
          help={getHelp(errors, moddedItem.key)}
          label={label}
          key={moddedItem.key}
          hidden={hidden}
          style={style}
          className={className}
          colon={colon}
        >
          {getFieldDecorator(
            moddedItem.key,
            getDecoratorConfig(moddedItem, data, isNew, form),
          )(field)}
        </Form.Item>
      </div>
    </>
  )
}

CmsFormItem.propTypes = propTypes
CmsFormItem.defaultProps = defaultProps

export default CmsFormItem
