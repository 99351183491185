exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_footer__2Hk0j {\n  max-width: 120rem;\n  margin: 0 auto; }\n\n.style_inner__39m3k {\n  padding: 3.07rem 2.3rem 2.3rem;\n  margin: 0rem 2.3rem 0;\n  border-radius: 5px 5px 0 0;\n  color: #514d6a;\n  background: #e4e9f0; }\n  @media (max-width: 767px) {\n    .style_inner__39m3k {\n      margin: 0 0.76rem !important; } }\n\n.style_inneralt__2vhXg {\n  padding: 0 2.3rem 2.3rem;\n  margin: 0rem 2.3rem 0;\n  color: #74708d;\n  text-align: center; }\n\n.style_bottom__1cFub {\n  border-top: 1px solid #d2d9e5;\n  padding-top: 1.53rem;\n  margin-top: 0.76rem; }\n\n.style_copyright__2gRIr {\n  float: right;\n  position: relative; }\n  @media (max-width: 575px) {\n    .style_copyright__2gRIr {\n      margin-top: 1.53rem;\n      float: none; } }\n  .style_copyright__2gRIr img {\n    float: left;\n    width: 30px;\n    position: absolute;\n    top: 6px; }\n  .style_copyright__2gRIr span {\n    display: block;\n    margin-left: 50px; }\n", ""]);

// exports
exports.locals = {
	"footer": "style_footer__2Hk0j",
	"inner": "style_inner__39m3k",
	"inneralt": "style_inneralt__2vhXg",
	"bottom": "style_bottom__1cFub",
	"copyright": "style_copyright__2gRIr"
};