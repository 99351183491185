import Decimal from 'decimal.js-light'

export const sumToDp = (precision = 2) => (val1, val2) =>
  new Decimal(val1).add(val2).todp(precision).toNumber()

export const timesToDp = (precision = 2) => (val1, val2) =>
  new Decimal(val1).times(val2).todp(precision).toNumber()

export const divideToDp = (precision = 2) => (val1, val2) =>
  new Decimal(val1).div(val2).todp(precision).toNumber()

export const subtractToDp = (precision = 2) => (val1, val2) =>
  new Decimal(val1).sub(val2).todp(precision).toNumber()

export const sum = sumToDp(2)

export const times = timesToDp(2)

export const divide = divideToDp(2)

export const subtract = subtractToDp(2)

/**
 * @param {number|Decimal} fraction
 * @return {number} percent
 */
export const mapFractionToPercent = (fraction) => new Decimal(fraction || 0).times(100).toNumber()

export const mapFractionsToPercents = (fractions = []) => fractions.map(mapFractionToPercent)

/**
 * @param {number|Decimal} percent
 * @return {number} fraction
 */
export const mapPercentToFraction = (percent) => new Decimal(percent || 0).div(100).toNumber()

export const mapPercentsToFractions = (percents = []) => percents.map(mapPercentToFraction)

export default {
  sum,
}
