import { useMemo } from 'react'
import { checkPermissions } from '../utils/permissions'
import useRole from './useRole'

export default function usePermissions(permissions) {
  const userRole = useRole()
  const hasPermissions = useMemo(() => checkPermissions(permissions)(userRole), [
    permissions,
    userRole,
  ])

  return hasPermissions
}
