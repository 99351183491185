import React from 'react'
import { call, put, takeEvery, all } from 'redux-saga/effects'
import { notification } from 'antd'

import API from '../services/api'
import dataSaga from './data/sagas'
import userSaga from './user/sagas'
import settingSaga from './settings/sagas'
import menuSaga from './menu/sagas'
import emailSaga from './email/sagas'
import tagsSaga from './tags/sagas'
import bondsSaga from './bonds/sagas'
import backendSettingsSaga from './backend_settings/saga'

import { saga as fundersSaga } from './funders'
import { saga as loansSaga } from './loans'
import { saga as invoicesSaga } from './invoices'
import { saga as propertiesSaga } from './properties'
import { saga as propertyInsuranceSaga } from './property_insurance'
import { saga as corporatesSaga } from './corporates'
import { saga as filesSaga } from './files'
import { saga as notesSaga } from './notes'
import { saga as usersSaga } from './users'
import { saga as changesSaga } from './changes'
import { saga as marketingGroups } from './marketing_groups'
import { saga as groupedFiles } from './grouped_files'
import { saga as eventsSaga } from './events'
import { saga as reportsSaga } from './reports'
import { saga as valuationsSaga } from './valuations'
import { saga as legalDocumentsSaga } from './legal_documents'
import { saga as taskListsSaga } from './task_lists'
import { saga as cashflowEventsSaga } from './cashflow_events'
import { saga as loanFeesSaga } from './loan_fee'
import { saga as loanInterestsSaga } from './loan_interest'
import { saga as loanSummariesSaga } from './loan_summaries'
import { saga as trackRecordsSaga } from './track_records'

notification.config({
  placement: 'bottomRight',
})

export default function* rootSaga(/* getState */) {
  yield all([
    emailSaga(),
    userSaga(),
    dataSaga(),
    settingSaga(),
    menuSaga(),
    tagsSaga(),
    bondsSaga(),
    fundersSaga(),
    corporatesSaga(),
    filesSaga(),
    notesSaga(),
    usersSaga(),
    changesSaga(),
    marketingGroups(),
    groupedFiles(),
    backendSettingsSaga(),
    eventsSaga(),
    loansSaga(),
    propertiesSaga(),
    invoicesSaga(),
    reportsSaga(),
    propertyInsuranceSaga(),
    valuationsSaga(),
    legalDocumentsSaga(),
    taskListsSaga(),
    cashflowEventsSaga(),
    loanFeesSaga(),
    loanInterestsSaga(),
    loanSummariesSaga(),
    trackRecordsSaga(),
  ])
}

const notifySuccess = (mode, responseData) => {
  switch (mode) {
    case 'EDIT':
    case 'UPDATE':
    case 'ADD':
    case 'REMOVE':
      notification.success({
        message: 'Success',
        // temporary fix; Final user cant see Bond, but messages are genereated automaticly by class name so this fixes that
        // TODO: rename Bond to Relation in the whole app (classNames, fileNames etc)
        description: responseData.message.replace('Bond', 'Relation'),
      })
      break
    default:
  }
}

export const notifyError = (mode, responseData) => {
  switch (mode) {
    case 'EDIT':
    case 'UPDATE':
    case 'ADD':
    case 'REMOVE':
    case 'FAILURE':
      notification.error({
        message: responseData.message,
        description: responseData.errors ? (
          <div>
            {Object.keys(responseData.errors).map((error) => (
              <p key={error}>
                <strong>{error}:</strong> {responseData.errors[error]}
              </p>
            ))}
          </div>
        ) : (
          responseData.description || ''
        ),
      })
      break
    default:
  }
}

export function createSaga(prefix, endpoint = null, notifications = true) {
  const endpointPrefix = endpoint || prefix

  function* fetch(action) {
    try {
      const response = yield call(API.call, endpointPrefix, 'GET', {
        ...action.query,
      })
      const responseBody = yield call([response, 'json'])

      if (response.status >= 400) {
        yield put({
          type: `${prefix}_FETCH_FAILURE`,
          payload: responseBody,
          statusCode: response.status,
        })
      } else {
        yield put({
          type: `${prefix}_FETCH_SUCCESS`,
          payload: responseBody,
          statusCode: response.status,
        })
      }
    } catch (e) {
      if (e.name === 'AbortError') {
        // don't do anything, this is aborted call
      } else {
        yield put({ type: `${prefix}_FETCH_FAILURE`, message: e.message })
      }
    }
  }

  function crud(mode = 'ADD') {
    /** method is either GET | ADD | EDIT | REMOVE | UPDATE */

    const method = {
      GET: 'GET',
      ADD: 'POST',
      EDIT: 'PUT',
      UPDATE: 'PATCH',
      REMOVE: 'DELETE',
    }

    return function* crudGenerator(action) {
      const endpointCrud = {
        ADD: `${endpointPrefix}`,
        EDIT: `${endpointPrefix}/${action.id}`,
        UPDATE: `${endpointPrefix}/${action.id}`,
        REMOVE: `${endpointPrefix}/${action.id}`,
        GET: `${endpointPrefix}/${action.id}`,
      }

      const data = Object.assign({}, action)
      try {
        const response = yield call(API.call, endpointCrud[mode], method[mode], action.data)
        const responseBody = yield call([response, 'json'])
        if (response.status >= 400) {
          yield put({
            type: `${prefix}_${mode}_FAILURE`,
            data,
            payload: responseBody,
            statusCode: response.status,
          })
          if (notifications) {
            notifyError(mode, responseBody)
          }
        } else {
          yield put({
            type: `${prefix}_${mode}_SUCCESS`,
            data,
            payload: responseBody,
            statusCode: response.status,
          })
          if (notifications) {
            notifySuccess(mode, responseBody)
          }
        }
      } catch (e) {
        // console.error('err', e)
        yield put({
          type: `${prefix}_${mode}_FAILURE`,
          data,
          message: e.message,
        })
        // notifyError(mode, responseBody)
      }
    }
  }

  return function* crudSaga() {
    yield all([
      takeEvery(`${prefix}_FETCH_REQUEST`, fetch),
      takeEvery(`${prefix}_ADD_REQUEST`, crud('ADD')),
      takeEvery(`${prefix}_UPDATE_REQUEST`, crud('UPDATE')),
      takeEvery(`${prefix}_EDIT_REQUEST`, crud('EDIT')),
      takeEvery(`${prefix}_REMOVE_REQUEST`, crud('REMOVE')),
      takeEvery(`${prefix}_GET_REQUEST`, crud('GET')),
    ])
  }
}
