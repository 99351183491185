/**
 * Return formatted string into british currency format string
 * @param {number} value decimal type value
 * @returns {string} converted string
 */
export const convertDecimalToCurrencyFormat = (value) => {
    return `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * Return string without any whitespace character
 * @param {string} string string to cleanup
 * @returns {string} String without any space
 */
export const removeAnyWhitespace = (string) => {
    return string.replace(/\s/g, '');
}

/**
 * Return converter string with number from value
 * @param {string} string string to convert
 * @returns {string} converter value
 */
export const convertToNumericString = (string) => {
    return string.replace(/[^\d.]+/gm, '');
}

/**
 * Return string that has trimmed char cut out to maximum limit
 * @param {string} stringToCheck string that has to been proceeded
 * @param {string} charToTrim char to check for cut out
 * @param {number} maxNumOfOccurrences maximum number of character appearance
 * @returns {string}
 */
export const trimRedundantChar = (stringToCheck, charToTrim = '.', maxNumOfOccurrences= 1) => {
    let result = ''
    let occurrences = 0

    stringToCheck.split('').forEach((element) => {
        if(element.includes(charToTrim))
            occurrences++

        if(occurrences > maxNumOfOccurrences)
            return

        result += element
    })

    return result;
}

export default {
    convertDecimalToCurrencyFormat,
    removeAnyWhitespace,
    convertToNumericString,
    trimRedundantChar
}


