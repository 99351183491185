export const fetchURLsFromLocalStorage = () => {
  const params = new URL(document.location).searchParams

  if (params.get('apiURL')) {
    localStorage.setItem('API_URL', `https://${params.get('apiURL')}/api/`)
  }
}

export default {
  fetchURLsFromLocalStorage,
}
