import API from './api'

const getTokenExpiryDate = (expiresIn) => {
  const now = new Date()
  now.setSeconds(now.getSeconds() + expiresIn)
  return now.getTime()
}

export const login = async (email, password) => {
  const response = await API.call('login', 'POST', { email, password })
  const body = await response.json()

  if (body.access_token) {
    // Login successfull
    API.token = body.access_token
    return Promise.resolve({
      success: true,
      token: body.access_token,
      expiry_date: getTokenExpiryDate(body.expires_in),
    })
  }

  return Promise.resolve(body)
}

export const refreshAccessToken = async () => {
  const response = await API.call('refresh_token', 'POST')
  const body = await response.json()

  if (body.access_token) {
    // Login successfull
    API.token = body.access_token
    return Promise.resolve({
      success: true,
      token: body.access_token,
      expiry_date: getTokenExpiryDate(body.expires_in),
    })
  }

  return Promise.resolve(body)
}

export const currentAccount = async () => {
  const response = await API.call('me')
  const body = await response.json()

  if (response.status === 200) {
    return Promise.resolve({
      ...body.user,
    })
  }

  return Promise.resolve({ token: '' })
}

export const register = async () => {
  // [POST] /register
}
