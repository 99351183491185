import React, { Component } from 'react'
import '../scss/app.scss'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

// import { fetch } from "./redux/data/actions";
import { notification } from 'antd'
import IndexLayout from './layouts'
import Router from './router'
import API from './services/api'
import { loadCurrentAccount, logout } from './redux/user/actions'
import { fetchURLsFromLocalStorage } from './services/initApiLocalStorage'
import { watchForTokenExpires, watchToTokenRefresh } from './services/tokenWatchers'

class App extends Component {
  componentWillMount() {
    // this.props.dispatch(fetch());
  }

  componentDidMount() {
    const now = new Date().getTime()

    // ? If token is expired (but existed in the past)
    if (this.props.user.expiry_date && now > this.props.user.expiry_date) {
      this.props.dispatch(logout())
      notification.info({
        message: 'Session',
        description: 'Your session is expired, please login again',
      })
    }

    if (this.props.user.token) {
      API.token = this.props.user.token
      this.props.dispatch(loadCurrentAccount())
    }

    watchToTokenRefresh()
    watchForTokenExpires()
    fetchURLsFromLocalStorage()
  }

  componentDidUpdate(prevProps) {
    // ? run token expiry date watcher, when it change
    if (prevProps.user.expiry_date !== this.props.user.expiry_date) {
      watchToTokenRefresh()
    }
  }

  render() {
    return (
      <IndexLayout>
        <Router />
      </IndexLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.Data,
  user: state.user,
})

export default withRouter(connect(mapStateToProps)(App))
