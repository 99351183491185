import { all, put, call } from 'redux-saga/effects'
/*





Marketing
CMS

*/
async function getTopMenuData() {
  return [
    {
      title: 'Homepage',
      key: 'homepage',
      icon: 'icmn icmn-home',
      url: '/dashboard',
    },
    {
      title: 'User Manual',
      key: 'definitions',
      target: '_blank',
      icon: 'icmn icmn-info',
      url: 'https://docs.londonwallgroup.app',
    },
    {
      title: 'Add new Loan',
      key: 'new-loan',
      icon: 'icmn icmn-plus utils__spin-delayed--pseudo-selector',
      url: '/loans/list/new',
    },
    {
      title: 'Pipeline',
      key: 'pipeline',
      icon: 'icmn icmn-rocket utils__spin-delayed--pseudo-selector',
      url: '/pipeline',
      children: [
        {
          key: 'pipeline/dashboard',
          title: 'Dashboard',
          url: '/pipeline/dashboard',
        },
        {
          key: 'pipeline/list',
          title: 'List',
          url: '/pipeline/list',
        },
        {
          key: 'not-proceeding/list',
          title: 'Not-Proceeding List',
          url: '/not-proceeding/list',
        },
      ],
    },
    {
      title: 'Loans',
      key: 'loans',
      icon: 'fa fa-percent utils__spin-delayed--pseudo-selector',
      url: '/loans',
      children: [
        {
          key: 'loans/dashboard',
          title: 'Dashboard',
          url: '/loans/dashboard',
        },
        {
          key: 'loans/list',
          title: 'List',
          url: '/loans/list',
        },
      ],
    },
    {
      title: 'Borrowers',
      key: 'borrowers',
      icon: 'icmn icmn-office',
      url: '/borrowers',
      children: [
        {
          key: 'borrowers/dashboard',
          title: 'Dashboard',
          url: '/borrowers/dashboard',
        },
        {
          key: 'borrowers/list',
          title: 'List',
          url: '/borrowers/list',
        },
      ],
    },
    {
      title: 'Funders',
      key: 'funders',
      icon: 'fa fa-institution',
      url: '/funders',
      children: [
        {
          key: 'funders/dashboard',
          title: 'Dashboard',
          url: '/funders/dashboard',
        },
        {
          key: 'funders/list',
          title: 'List',
          url: '/funders/list',
        },
      ],
    },
    {
      title: 'CMS',
      key: 'cms',
      icon: 'fa fa-file',
      url: '/cms',
      children: [
        {
          key: 'funder_introducers',
          title: 'Funder Introducers',
          url: '/cms/funder_introducers',
        },
        {
          key: 'lending_platforms',
          title: 'Lending platforms',
          url: '/cms/lending_platforms',
        },
        {
          key: 'brokers',
          title: 'Brokers',
          url: '/cms/brokers',
        },
        {
          key: 'legal_firms',
          title: 'Legal Firms',
          url: '/cms/legal_firms',
        },
        {
          key: 'valuation_firms',
          title: 'Valuation Firms',
          url: '/cms/valuation_firms',
        },
        {
          key: 'project_monitors',
          title: 'Project Monitors',
          url: '/cms/project_monitors',
        },
        {
          key: 'construction_companies',
          title: 'Construction Companies',
          url: '/cms/construction_companies',
        },
        {
          key: 'personal_guarantors',
          title: 'Personal Guarantors',
          url: '/cms/personal_guarantors',
        },
        {
          key: 'insurers',
          title: 'Insurers',
          url: '/cms/insurers',
        },
        {
          key: 'lw_spv',
          title: 'LW SPV',
          url: '/cms/lw_spv',
        },
        {
          title: 'People',
          key: 'people',
          url: '/cms/people',
        },
        {
          title: 'Marketing',
          key: 'marketing',
          url: '/cms/marketing',
        },
        {
          key: 'marketing_groups',
          title: 'Marketing Groups',
          url: '/cms/marketing_groups',
        },
        {
          key: 'shareholders',
          title: 'Shareholders',
          url: '/cms/shareholders',
        },
      ],
    },
    {
      title: 'Reports',
      key: 'reports',
      icon: 'icmn icmn-stats-bars',
      url: '/reports',
    },
    {
      title: 'Tasks',
      key: 'tasks',
      icon: 'icmn icmn-clipboard',
      url: '/tasks',
    },
    {
      title: 'Tools',
      key: 'tools',
      icon: 'icmn icmn-wrench',
      url: '/tools',
      children: [
        {
          title: 'Users',
          key: 'users',
          icon: 'icmn icmn-user',
          url: '/users',
        },
        {
          title: 'Emails',
          key: 'emails',
          icon: 'icmn icmn-mail4',
          url: '/emails',
        },
        {
          title: 'DMS',
          key: 'dms',
          icon: 'icmn icmn-attachment',
          url: '/dms',
        },
        {
          title: 'LW Fee',
          key: 'lwfee',
          icon: 'icmn icmn-coin-dollar',
          url: '/lwfee',
        },
      ],
    },
  ]
}

export function* GET_DATA() {
  const menuTopData = yield call(getTopMenuData)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuTopData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
