import React from 'react'
import _ from 'lodash'
import { Avatar } from 'antd'
import moment from 'moment'
import CmsFormItem from '../components/CmsFormItem'
import { version } from '../../../package.json'
import { CALC_DATE_FORMAT, VISIBLE_DATE_FORMAT } from '../config/dates'
import { sum } from './math'

/**
 * Method to filter arrays.
 * Removes an object from the array whose parameter (key) is the same as the parameter of the object specified in the second argument (objToCompare).
 * @param {string} key
 * @param {object} objToCompare
 * @returns {function(object): boolean}
 */
export const filterByKey = (key, objToCompare) => (arrItem) => arrItem[key] !== objToCompare[key]

/**
 * Map the ant form's values to an API-compliant format
 * @param {object} submitValues values returned ant from's getFieldsValue or validateFields methods
 * @param {array} fields Array of fields configs
 * @param {string} [filterKey] key to filter only active fields
 * @returns {Object} mapped values to API format
 */
export const mapFormValuesToApiFormat = (submitValues, fields, filterKey = 'editable') => {
  const values = Object.assign({}, submitValues)

  fields
    .filter((field) => field[filterKey])
    .forEach((field) => {
      if (field.formatSubmitValue) {
        values[field.key] = field.formatSubmitValue(values[field.key])
      } else if (field.type === 'checkbox') {
        if (_.isUndefined(values[field.key]) || _.isBoolean(values[field.key])) {
          values[field.key] = values[field.key] ? 1 : 0 // replacing true/false with 1/0 so it works with API syntax
        }
      } else if (field.type === 'hasmany') {
        values[field.key] = (values[field.key] || []).map((v) => (Number.isInteger(v) ? v : v.id))
      } else if (field.type === 'groups') {
        values[field.key] = (values[field.key] || [])
          .filter((em) => !!em) // fix rerender bug
          .map((groupValues) => mapFormValuesToApiFormat(groupValues, field.children))
      } else if (
        field.type === 'date' &&
        !_.isUndefined(values[field.key]) &&
        values[field.key] !== null
      ) {
        values[field.key] = values[field.key].format(CALC_DATE_FORMAT)
      }
    })

  return values
}

export const NEW_LINE = /\r?\n/

export const mapTextToNewLines = (text) => {
  return (
    <>
      {(text || '').split(NEW_LINE).map((row) => (
        <React.Fragment key={`${row}_${Math.random()}`}>
          {row}
          <br />
        </React.Fragment>
      ))}
    </>
  )
}

/* eslint-disable */
export const flattenObject = (ob, infix = '.') => {
  const toReturn = {}
  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue

    if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
      const flatObject = flattenObject(ob[i])
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue

        toReturn[i + infix + x] = flatObject[x]
      }
    } else {
      toReturn[i] = ob[i]
    }
  }
  return toReturn
}
/* eslint-enable */

export const getUserAvatar = (username, params = {}) => {
  const style = {}
  let UN = ''

  if (username !== 'Anonymous') {
    style.backgroundColor = `#d8ebb5`
    const splitted = username.split(' ')
    UN = splitted.reduce((acc, curr) => acc + curr.charAt(0), '')
  } else {
    params.icon = 'user'
  }

  return (
    <Avatar {...params} style={style}>
      {UN}
    </Avatar>
  )
}

export const formatStaticValue = (value, type, shouldCapitalize = true) => {
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  })

  if (value === '' || typeof value === 'undefined') {
    value = '----'
  }

  switch (type) {
    case 'date':
      return (moment.isMoment(value) ? value : moment(value)).format(VISIBLE_DATE_FORMAT)
    case 'amount':
      return formatter.format(value)
    case 'percent':
      return `${value}%`
    case 'checkbox':
      return value ? 'Yes' : 'No'
    default:
  }

  if (typeof value === 'object') {
    return value ? value.name : '----'
  }

  return shouldCapitalize ? _.capitalize(value) : value
}

export const getMappedFormItems = (array, data, formRef) =>
  array.map((item) => <CmsFormItem key={item.key} item={item} data={data} form={formRef} />)

export const formatClassTypeToNiceString = (classType) => {
  return classType
    .replace(/(_|-)/g, ' ')
    .trim()
    .replace(/\w\S*/g, (str) => {
      return str.charAt(0).toUpperCase() + str.substr(1)
    })
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
}

export const sortByMomentDateFunc = (a, b) =>
  moment(a).format('YYYYMMDD') - moment(b).format('YYYYMMDD')

/**
 * Returns sorted by moment date array
 *
 * @param {Array} array - array of Objects to sort
 * @param {String} key - key of object having date to sort by
 *
 * @returns {Array}
 */
export const sortByMomentDate = (array = [], key = '') => {
  return array.sort((a, b) => sortByMomentDateFunc(a[key], b[key]))
}

/**
 * Returns the copy of the array with removed Entity of given ID
 *
 * @param {Array} array - array of objects
 * @param {Number} id - ID of the entity to remove
 */
export const removeIdFromArray = (array, id) => {
  const arrayCopy = [...array]
  const index = arrayCopy.findIndex((el) => el.id === id)
  arrayCopy.splice(index, 1)
  return arrayCopy
}

export const formatShareholders = (values) => {
  const { shareholders } = values
  if (!shareholders) return values

  return {
    ...values,
    shareholders: shareholders.filter(
      (el) => typeof el.share_percent === 'number' && el.shareholder_id,
    ),
  }
}

export const isArrayEqual = (x, y) => {
  // return _(x).xor(y, _.isEqual).isEmpty()
  return _.isEqual(x, y)
}

/**
 * Map recurently every key in object and apply mapFunction on it
 * @param {(o: Object) => Object} mapFunction
 * @returns {(o: Object) => Object}
 */
export const deeplyMapObjectKeys = (mapFunction) => (object) => {
  // Return value if no keys to map
  if (!_.isPlainObject(object) && !_.isArray(object)) return object

  if (_.isArray(object)) {
    return object.map(deeplyMapObjectKeys(mapFunction))
  }

  const keys = Object.keys(object)
  const result = {}
  keys.forEach((key) => {
    const newKey = mapFunction(key)

    if (_.isArray(object[key])) {
      // If key is array, map all children
      result[newKey] = object[key].map(deeplyMapObjectKeys(mapFunction))
    } else if (_.isPlainObject(object[key])) {
      // if key is object, map all children keys
      result[newKey] = deeplyMapObjectKeys(mapFunction)(object[key])
    } else {
      result[newKey] = object[key]
    }
  })
  return result
}

export const mapKeysToCamelCase = deeplyMapObjectKeys(_.camelCase)
export const mapKeysToSnakeCase = deeplyMapObjectKeys(_.snakeCase)

export const isSuccessStatus = (status) =>
  !!status && ((status >= 200 && status < 300) || status === 304)

export const round = (number, places = 0) => Math.round(number * 10 ** places) / 10 ** places

export const getVersion = () =>
  `${window.location.host === 'londonwallgroup.app' ? '' : 'Dev'} v${version}`

/**
 * Returns sum of keys in object array
 * @param {string} key
 * @returns {(array: Object[]) => number}
 */
export const sumObjectsProperty = (key) => (array) => array.map((e) => e[key]).reduce(sum, 0)

/**
 * Returns array sorted by keys
 * @param {string} key
 * @returns {(array: Object[]) => number}
 */
export const sortByObjectsProperty = (key) => (array) => [...array].sort((a, b) => b[key] - a[key])

export const mapEnumToSelectOptions = (enumObject) =>
  Object.keys(enumObject).map((key) => ({
    value: key,
    label: enumObject[key],
  }))

export default {
  isArrayEqual,
  filterByKey,
  mapFormValuesToApiFormat,
  NEW_LINE,
  mapTextToNewLines,
  flattenObject,
  getUserAvatar,
  formatStaticValue,
  getMappedFormItems,
  sortByMomentDateFunc,
  sortByMomentDate,
  removeIdFromArray,
  formatClassTypeToNiceString,
  deeplyMapObjectKeys,
  mapKeysToCamelCase,
  mapKeysToSnakeCase,
  isSuccessStatus,
}
