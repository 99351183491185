export const ROLES = {
  noAccess: 0,
  superAdmin: 1,
  fullAdmin: 2,
  restrictedAdmin: 3,
  viewOnly: 4,
  restrictedViewOnly: 5,
}

export const ROLES_NAMES = {
  [ROLES.superAdmin]: 'LW Super Admin',
  [ROLES.fullAdmin]: 'LW Admin',
  [ROLES.restrictedAdmin]: 'LW Restricted Admin',
  [ROLES.viewOnly]: 'View Only',
  [ROLES.restrictedViewOnly]: 'Restricted View Only',
}

export default {
  ROLES,
  ROLES_NAMES,
}
