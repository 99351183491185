import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { login, currentAccount, refreshAccessToken } from '../../services/user'
import { notifyError } from '../sagas'
import {
  LOGIN,
  LOAD_CURRENT_ACCOUNT,
  LOGOUT,
  REFRESH_TOKEN,
  setState,
  loadCurrentAccount,
} from './actions'
import { store } from '../store'
import API from '../../services/api'
import { setAccessToken } from '../../services/token'

export function* loginSaga({ payload }) {
  const { email, password } = payload
  yield put(setState({ loading: true }))
  const response = yield call(login, email, password)
  if (response.success) {
    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in to LW Databases!',
    })
    setAccessToken(response.token)
    yield put(
      setState({
        token: response.token,
        expiry_date: response.expiry_date,
      }),
    )
    yield put(loadCurrentAccount())
  } else {
    notifyError('FAILURE', { ...response, description: response.message, message: 'Login failed' })
  }
  yield put(setState({ loading: false }))
}

export function* refreshTokenSaga() {
  yield put(setState({ loading: true }))
  const state = store.getState()
  const response = yield call(refreshAccessToken, state.user.refresh_token)
  if (response.success) {
    setAccessToken(response.token)
    yield put(
      setState({
        token: response.token,
        expiry_date: response.expiry_date,
      }),
    )
  } else {
    notifyError('FAILURE', {
      ...response,
      description: response.message,
      message: 'Token refresh failed',
    })
  }
  yield put(setState({ loading: false }))
}

export function* loadCurrentAccountSaga() {
  yield put(
    setState({
      loading: true,
    }),
  )
  const response = yield call(currentAccount)
  if (response) {
    yield put(setState({ ...response }))
  }
  yield put(setState({ loading: false }))
}

export function* logoutSaga() {
  API.token = ''
  yield put(
    setState({
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      loading: false,
      token: '',
    }),
  )
}

export default function* rootSaga() {
  yield all([
    takeEvery(LOGIN, loginSaga),
    takeEvery(LOAD_CURRENT_ACCOUNT, loadCurrentAccountSaga),
    takeEvery(REFRESH_TOKEN, refreshTokenSaga),
    takeEvery(LOGOUT, logoutSaga),
  ])
}
