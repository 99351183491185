import * as ACTION from './actions'

export const INIT_STATE = {
  loading: false,
  error: false,
  titles: [],
  byClassbyId: {},
  byBondClassbyId: {},
}

export default function reducer(state = INIT_STATE, action) {
  if (state === undefined) {
    return INIT_STATE
  }
  let newState
  let newState2
  switch (action.type) {
    // fetch
    case ACTION.FETCH_SUCCESS:
      newState = Object.assign({}, state.byClassbyId)
      newState2 = Object.assign({}, state.byBondClassbyId)
      action.payload.data.forEach((bond) => {
        newState[bond.class_type] = newState[bond.class_type] || {}
        newState[bond.class_type][bond.class_id] = newState[bond.class_type][bond.class_id] || []
        newState[bond.class_type][bond.class_id][bond.id] = bond

        newState2[bond.bond_type] = newState2[bond.bond_type] || {}
        newState2[bond.bond_type][bond.bond_id] = newState2[bond.bond_type][bond.bond_id] || []
        newState2[bond.bond_type][bond.bond_id][bond.id] = bond
      })

      return {
        ...state,
        byClassbyId: newState,
        byBondClassbyId: newState2,
      }
    case ACTION.FETCH_REQUEST:
      return state
    case ACTION.FETCH_FAILURE:
      return state

    // add
    case ACTION.ADD_SUCCESS:
      newState = Object.assign({}, state.byClassbyId)
      newState[action.payload.data.class_type] = newState[action.payload.data.class_type] || {}
      newState[action.payload.data.class_type][action.payload.data.class_id] =
        newState[action.payload.data.class_type][action.payload.data.class_id] || []
      newState[action.payload.data.class_type][action.payload.data.class_id][
        action.payload.data.id
      ] = action.payload.data
      return {
        ...state,
        byClassbyId: newState,
      }
    case ACTION.ADD_REQUEST:
      newState = Object.assign({}, state.byClassbyId)
      newState[action.data.class_type] = newState[action.data.class_type] || {}
      newState[action.data.class_type][action.data.class_id] =
        newState[action.data.class_type][action.data.class_id] || []
      return state
    case ACTION.ADD_FAILURE:
      return state

    // remove
    case ACTION.REMOVE_SUCCESS:
      return state
    case ACTION.REMOVE_REQUEST:
      newState = Object.assign({}, state.byClassbyId)
      newState2 = Object.assign({}, state.byBondClassbyId)

      delete newState[action.data.class_type][action.data.class_id][action.id]

      if (
        newState2[action.data.bond_type] !== undefined &&
        newState2[action.data.bond_type][action.data.bond_id] !== undefined &&
        newState2[action.data.bond_type][action.data.bond_id][action.id] !== undefined
      ) {
        delete newState2[action.data.bond_type][action.data.bond_id][action.id]
      }

      return {
        ...state,
        byClassbyId: newState,
        byBondClassbyId: newState2,
      }

    case ACTION.REMOVE_FAILURE:
      return state

    default:
      return state
  }
}
