import Data from './data/reducer'
import user from './user/reducers'
import settings from './settings/reducers'
import menu from './menu/reducers'
import email from './email/reducer'
import tags from './tags/reducer'
import bonds from './bonds/reducer'
import backendSettings from './backend_settings/reducer'

import { reducer as funders } from './funders'
import { reducer as loans } from './loans'
import { reducer as reports } from './reports'
import { reducer as invoices } from './invoices'
import { reducer as propertyInsurance } from './property_insurance'
import { reducer as properties } from './properties'
import { reducer as corporates } from './corporates'
import { reducer as files } from './files'
import { reducer as notes } from './notes'
import { reducer as users } from './users'
import { reducer as changes } from './changes'
import { reducer as marketingGroups } from './marketing_groups'
import { reducer as groupedFiles } from './grouped_files'
import { reducer as events } from './events'
import { reducer as valuations } from './valuations'
import { reducer as legalDocuments } from './legal_documents'
import { reducer as taskLists } from './task_lists'
import { reducer as cashflowEvents } from './cashflow_events'
import { reducer as loanFees } from './loan_fee'
import { reducer as loanInterests } from './loan_interest'
import { reducer as loanSummaries } from './loan_summaries'
import { reducer as trackRecords } from './track_records'

export const mapClassNamesToRedux = {
  Data: 'Data',
  Settings: 'settings',
  Menu: 'menu',
  Email: 'email',
  Tag: 'tags',
  Bond: 'bonds',
  Funder: 'funders',
  Corporate: 'corporates',
  File: 'files',
  Note: 'notes',
  Loan: 'loans',
  Property: 'properties',
  User: 'users',
  Change: 'changes',
  MarketingGroup: 'marketingGroups',
  GroupedFile: 'groupedFiles',
  Invoices: 'invoices',
  Event: 'events',
  PropertyInsurance: 'propertyInsurance',
  Report: 'reports',
  Valuation: 'valuations',
  LegalDocument: 'legal_documents',
  TaskList: 'task_lists',
}

export default {
  Data,
  user,
  settings,
  menu,
  email,
  tags,
  bonds,
  funders,
  corporates,
  loans,
  properties,
  propertyInsurance,
  files,
  notes,
  users,
  changes,
  marketingGroups,
  groupedFiles,
  backendSettings,
  events,
  invoices,
  reports,
  valuations,
  legalDocuments,
  taskLists,
  cashflowEvents,
  loanFees,
  loanInterests,
  loanSummaries,
  trackRecords,
}
