import React from 'react'
import { connect } from 'react-redux'
import { Menu, Dropdown } from 'antd'
import styles from './style.module.scss'

import { logout } from '../../../redux/user/actions'
import { getUserAvatar } from '../../../utils/utils'

@connect(({ user }) => ({ user }))
class ProfileMenu extends React.Component {
  logout = () => {
    const { dispatch } = this.props

    dispatch(logout())
  }

  render() {
    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <div>
            <strong>Username: </strong>
            {this.props.user.name}
            <br />
            <strong>Email: </strong>
            {this.props.user.email}
            <br />
            <strong>Role: </strong>
            {(this.props.user.roles || []).map((r) => r.display_name).join(', ')}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href={`#/users/${this.props.user.id}`}>
            <i className={`${styles.menuIcon} icmn-user`} />
            Edit profile
          </a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="#/user/login" onClick={this.logout}>
            <i className={`${styles.menuIcon} icmn-exit`} />
            Logout
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          {getUserAvatar(this.props.user.name, {
            shape: 'square',
            size: 'large',
            className: styles.avatar,
          })}
        </div>
      </Dropdown>
    )
  }
}

export default ProfileMenu
