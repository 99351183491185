/* eslint-disable */

const yBugEnabled = true || window.location.hostname !== 'localhost'

if (yBugEnabled) {
  //<!-- Ybug code -->
  ;(function () {
    window.ybug_settings = {
      id: 'wj0q47gztf',
      translate: {
        'launcherButton.Title': 'Zgłoś problem',
      },
    }
    var ybug = document.createElement('script')
    ybug.type = 'text/javascript'
    ybug.async = true
    ybug.src = 'https://ybug.io/api/v1/button/' + window.ybug_settings.id + '.js'
    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(ybug, s)
  })()
  //<!-- Ybug code end -->
}
